import React from "react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { ISlideBaseProps } from "@ryerson/frontend.carousel";
import { Icon } from "@ryerson/frontend.assets";
import { css } from "@emotion/react";
import { Video } from "@ryerson/frontend.media";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";

export interface QuoteWithVideoProps extends ISlideBaseProps {
	quote: string;
	name: string;
	title: string;
	imageUrl: string;
	videoId: string;
}

const AddMargin = css`
	margin-top: 50px;
`;
const MainText = styled(FlexItem)`
	position: relative;
	z-index: 1;
	width: 720px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.xl}) {
				width: 900px;
			}
		`;
	}}
`;
const LeftContent = styled(Flex)`
	width: 100%;
	h2 {
		margin-top: 0px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				width: 50%;
			}
			@media (min-width: 560px) and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 30px;
			}
		`;
	}}
`;
const SecondaryText = styled.div`
	${(props: any) => {
		const { theme } = props;
		return `
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 27px;
			}
		`;
	}}
`;
const StartQuote = styled(Icon)`
	margin-right: 12px;
	${(props: any) => {
		const { theme } = props;
		return `
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-right: 5px;
				left: -10px;	
			}
		`;
	}}
`;
const EndQuote = styled(Icon)`
	transform: rotate(180deg) translateY(8px);
`;

const DesktopBeginningQuote = styled.span`
	position: absolute;
	left: -25px;
	top: 0;
	font-size: 30px;
	@media (max-width: 1100px) {
		left: -20px;
	}
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.lightGray};
		`;
	}}
`;
const DesktopEndingQuote = styled.span`
	font-size: 30px;
	margin-left: 12px;
	${(props: any) => {
		const { theme } = props;
		return css`
			color: ${theme.colors.primary.lightGray};
		`;
	}}
`;
const VideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { theme, buttonOrientation = "left", buttonSize = "md" } = props;
		return css`
			width: 100%;
			@media only screen and (max-width: ${theme.breakpoints.sm}) {
				min-width: 300px;
				min-height: 200px;
				margin-bottom: 30px;
			}
			@media (min-width: ${theme.breakpoints.sm}) and (max-width: ${theme.breakpoints.lg}) {
				height: 350px;
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				height: 360px;
				margin-top: 140px;
			}
			@media only screen (min-width: 1100px) {
				min-width: 560px;
			}
			@media only screen and (min-width: ${theme.breakpoints.xl}) {
				margin-top: 150px;
			}
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, true, buttonSize)};
			}
			@media only screen and (min-width: ${theme.breakpoints.lg}) {
				${buttonOrientation === "center"
					? 0
					: getVideoPadding(buttonOrientation, false, buttonSize)};
			}
		`;
	}}
`;

const QuoteWithVideo: React.FC<QuoteWithVideoProps> = ({
	quote,
	name,
	title,
	imageUrl,
	videoId,
}) => {
	const { theme } = useTheme();
	return (
		<>
			<Media lessThan="lg">
				<Flex direction="column">
					<Flex
						direction="column"
						style={css`
							position: relative;
						`}
					>
						<FlexItem>
							<VideoWrapper theme={theme} buttonOrientation="bottom">
								<Video
									videoId={videoId}
									imageUrl={imageUrl}
									type="secondary"
									buttonOrientation="bottom"
								></Video>
							</VideoWrapper>
						</FlexItem>
					</Flex>
					<LeftContent theme={theme}>
						<FlexItem>
							<StartQuote
								theme={theme}
								icon="quote"
								size="xs"
								color={theme.colors.primary.lightGray}
							/>
						</FlexItem>
						<FlexItem>
							<Typography variant="div" color={theme.colors.primary.gray} size="xl">
								{quote}
								<EndQuote
									icon="quote"
									size="xs"
									color={theme.colors.primary.lightGray}
								/>
							</Typography>
							<SecondaryText theme={theme}>
								<Typography variant="div" size="md" weight="bold">
									{name}
								</Typography>
								<Typography
									variant="div"
									size="md"
									color={theme.colors.primary.gray}
								>
									{title}
								</Typography>
							</SecondaryText>
						</FlexItem>
					</LeftContent>
				</Flex>
			</Media>
			<Media greaterThanOrEqual="lg">
				<Flex css={AddMargin}>
					<LeftContent theme={theme} direction="column">
						<MainText theme={theme}>
							<Flex>
								<FlexItem>
									<Typography
										css={css`
											letter-spacing: -2px;
											margin: 0;
											position: relative;
										`}
										variant="h2"
										color={theme.colors.primary.gray}
									>
										<DesktopBeginningQuote theme={theme}>
											“
										</DesktopBeginningQuote>
									</Typography>
								</FlexItem>
								<FlexItem>
									<Typography
										variant="h2"
										color={theme.colors.primary.gray}
										css={{ marginBottom: "40px" }}
									>
										{quote}
										<DesktopEndingQuote theme={theme}>”</DesktopEndingQuote>
									</Typography>
									<SecondaryText theme={theme}>
										<Typography variant="div" size="sm" weight="bold">
											{name}
										</Typography>
										<Typography
											variant="div"
											size="sm"
											color={theme.colors.primary.gray}
										>
											{title}
										</Typography>
									</SecondaryText>
								</FlexItem>
							</Flex>
						</MainText>
					</LeftContent>
					<Flex
						direction="column"
						style={css`
							width: 100%;
						`}
					>
						<FlexItem>
							<VideoWrapper theme={theme}>
								<Video
									videoId={videoId}
									imageUrl={imageUrl}
									type="secondary"
									buttonOrientation="left"
								></Video>
							</VideoWrapper>
						</FlexItem>
					</Flex>
				</Flex>
			</Media>
		</>
	);
};

export default QuoteWithVideo;
